'use strict'

angular.module('cb.param-service', [])
    .factory('ParamService', ["ApiService", function (ApiService) {

        var paramService = {
            getParamByEntityAndField: function (entity, field) {
                try {
                    var data = { Entity: entity, Field: field }
                    var url = '/webapi/api/v1/param/getParamByEntityAndField';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
        };

        return paramService;
    }]);